<template>
  <div>
    <div class="row" v-if="!chatMode">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <a-input placeholder="Gunakan 0 bukan 62 Ex: 085xxxxxx115" v-model="nomor" @keydown.native.enter="checkNomer"></a-input>
      </div>
      <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <a-button class="btn btn-outline-primary" @click="checkNomer">Cari</a-button>
      </div>
        </div>
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
        <strong>Contact List</strong>
        <a-table
          class="mt-2"
          size="small"
          :columns="columnDefs"
          :dataSource="dataDefs"
          :scroll="{ y: 300 }"
          :pagination="{
            defaultPageSize: 200,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
            hideOnSinglePage: true,
          }"
        >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px;"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                  @change="
                    (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px;"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                >
                  Search
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px;"
                  @click="() => handleReset(clearFilters)"
                >
                  Reset
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>
                  <span v-if="column.dataIndex === 'name'">
                    {{ text }}
                  </span>
                  <span v-else>
                    {{ text }}
                  </span>
                </template>
              </template>
          <span slot="action" slot-scope="text, record">
            <!-- <a
                href="javascript:void(0)"
                @click="sendToForm('Update', record)"
                class="text-warning"
                >Edit</a
              >
              <a-divider type="vertical" v-if="index !== 0" /> -->
            <a href="javascript:void(0)" @click="getConversation(record.id)" class="text-primary mr-2">
              <a-icon type="message" />
            </a>
            <a href="javascript:void(0)" @click="blockedOrUnblocked(record, 'block')" class="text-danger">
              <a-icon type="right-circle" />
            </a>
          </span>
        </a-table>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
        <strong>Blocked Contact List</strong>
        <a-table
          class="mt-2"
          size="small"
          :columns="columnDefsBlocked"
          :dataSource="dataDefsBlocked"
          :scroll="{ y: 300 }"
          :pagination="{
            defaultPageSize: 200,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
            hideOnSinglePage: true,
          }"
        >
          <span slot="action" slot-scope="text, record">
            <!-- <a
                href="javascript:void(0)"
                @click="sendToForm('Update', record)"
                class="text-warning"
                >Edit</a
              >
              <a-divider type="vertical" v-if="index !== 0" /> -->
            <a href="javascript:void(0)" @click="blockedOrUnblocked(record, 'unblock')" class="text-success">
              <a-icon type="left-circle" />
            </a>
          </span>
        </a-table>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <div slot="title">
            <a-avatar class="mr-2" style="cursor: pointer;" @click="chatMode = false">
              <a-icon type="arrow-left"/>
            </a-avatar>
            <strong>Conversation</strong>
          </div>
      <div class="demo-infinite-container">
        <a-list size="small" item-layout="horizontal" :data-source="messages">
            <a-list-item slot="renderItem" slot-scope="item" style="border-bottom: unset !important;" class="mt-1">
              <a-list-item-meta v-if="item.message!== undefined && !item.key.fromMe">
                <strong slot="title">{{ item.remoteJid }}</strong>
                <p slot="description">{{ getMessage(item) }}</p>
                <a-avatar
                  slot="avatar"
                  class="mt-2"
                  src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                />
              </a-list-item-meta>

              <a-avatar
                v-else-if="item.message!== undefined && item.key.fromMe"
                slot="actions"
                class="mt-2"
                src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
              />
              <a-list-item-meta v-if="item.message!== undefined && item.key.fromMe">
                <div slot="title" href="https://www.antdv.com/" class="mt-2 pull-right">
                <!-- <strong>{{ item.key.fromMe ? item.key.remoteJid : 'Me' }}</strong> -->
                <strong>Me</strong>
                <br>
                <p class="pull-right" style="font-size: 14px !important; line-height: 22px !important;">{{ getMessage(item) }}</p>
                </div>
                <!-- <a-avatar
                  slot="avatar"
                  class="mt-2"
                  src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                /> -->
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
        <div class="input-message mt-2">
          <a-input-search
            placeholder="Masukan Pesan yang ingin di kirim."
            enter-button="Send"
            size="large"
            v-model="messagesValue"
            @search="sendMessages"
          />
          <!-- <strong>Send Message</strong> -->
        </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { mapState } from 'vuex'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      messagesValue: '',
      sessionKey: '',
      nomor: '',
      chatMode: false,
      messages: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columnDefsBlocked: [
        {
          title: '#',
          scopedSlots: {
            customRender: 'action',
          },
          width: 50,
        },
        {
          title: 'Nomor',
          dataIndex: 'id',
          // class: 'bg-transparent text-gray-6',
        },
      ],
      columnDefs: [
        // {
        //   title: 'pkId',
        //   dataIndex: 'pkId',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'sessionId',
        //   dataIndex: 'sessionId',
        //   // class: 'bg-transparent text-gray-6',
        // },
        {
          title: 'Nomor',
          dataIndex: 'id',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.id.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // class: 'bg-transparent text-gray-6',
        },
        {
          title: 'name',
          dataIndex: 'name',
          // class: 'bg-transparent text-gray-6',
        },
        // {
        //   title: 'notify',
        //   dataIndex: 'notify',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'verifiedName',
        //   dataIndex: 'verifiedName',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'imgUrl',
        //   dataIndex: 'imgUrl',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'status',
        //   dataIndex: 'status',
        //   // class: 'bg-transparent text-gray-6',
        // },
        {
          title: '#',
          scopedSlots: {
            customRender: 'action',
          },
          width: 70,
        },
      ],
      dataDefs: [],
      dataDefsBlocked: [],
    }
  },
  created () {
    this.checkActiveSessionsFromApi()
    this.sessionKey = this.user.activeSessions
    this.checkStatusSessions()
  },
  methods: {
    moment,
    async checkActiveSessionsFromApi() {
      var res = await lou.customUrlGet2('sessions/active')
      if (res) {
        this.user.activeSessions = res.sessionId
      }
    },
    async sendMessages(value) {
      console.log('value', value)
      if (value === '') {
        this.$message.error('Input tidak boleh kosong!')
      } else {
        var objsend = {
          jid: this.nomor,
          type: 'number',
          message: {
            text: value,
            mentions: [],
          },
        }
        var res = await lou.customUrlPost2(this.sessionKey + '/messages/send', objsend)
        if (res) {
          if (res.status === 'status') {
            lou.shownotif('Success', 'Pesan sedang terkirim.', 'success')
          }
        }
      // console.log(value)
      }
    },
    async getConversation(id) {
      var res = await lou.customUrlGet2(this.sessionKey + '/chats/' + id + '?limit=100')
      if (res) {
        this.activeConversation = id
        this.nomor = id
        this.messages = res.data
        this.chatMode = true
      }
    },
    getMessage(message) {
      // var index = this.messages.findIndex(x => x.id === message.id)
      // console.log('index', index)
      // console.log('message', message)
      if (message.message !== undefined) {
        console.log('message.message', message.message)
        if (message.message.conversation !== undefined) {
          console.log('message.message.conversation', message.message.conversation)
          return message.message.conversation
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    async checkStatusSessions() {
      var res = await lou.customUrlGet2(
        'sessions/' + this.sessionKey + '/status',
      )
      if (res) {
        if (res.status === 'CONNECTED') {
          var delet = await lou.customUrlDelete2(
            'sessions/' + this.sessionKey,
          )
          console.log('delet', delet)
          lou.shownotif('Error', 'Sesi belum connect dengan whatsapp', 'error')
        } else if (res.status === 'AUTHENTICATED') {
          this.getList()
        }
      } else {
        lou.shownotif('Error', 'Sesi tidak ada atau belum connect dengan whatsapp', 'error')
      }
    },
    async checkNomer() {
      var res = await lou.customUrlGet2(this.sessionKey + '/contacts/' + this.nomor)
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        if (res.exists) {
          // this.$message.success('Nomor hp ada.')
          Modal.confirm({
            title: 'You want to chat ' + this.nomor + '?',
            // content: "You can't prevent this!!! ",
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            onOk: () => {
              this.getConversation('62' + this.nomor.substring(1) + '@s.whatsapp.net')
            },
            onCancel: () => {
              console.log('Cancel')
            },
          })
        } else {
          this.$message.error('Nomor hp tidak ada.')
        }
        // this.dataDefs = res.data
      }
    },
    async getList() {
      var res = await lou.customUrlGet2(this.sessionKey + '/contacts?limit=500')
      var resBlocked = await lou.customUrlGet2(this.sessionKey + '/contacts/blocklist')
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        console.log('res', res)
        this.dataDefs = res.data
        var blockList = []
        console.log('resBlocked', resBlocked)
        resBlocked.forEach(element => {
          blockList.push({ id: element, sessionId: this.sessionKey })
        })
        this.dataDefsBlocked = blockList
      }
    },
    async blockedOrUnblocked(item, action = 'block') {
      var res = await lou.customUrlPost2(item.sessionId + '/contacts/blocklist/update', {
        jid: item.id,
        action: action,
      })
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        if (action === 'unblock') {
          this.$message.success('The Contact Has Been Unblocked.')
        } else {
          this.$message.error(res.message)
        }
        this.getList()
        // this.dataDefs = res.data
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 250px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>
